import styled from "styled-components";

export const Container = styled.form`
  background-color: rgba(28, 75, 18, 0.8);
  border-radius: 30px 30px 0 0;
  max-width: 60%;
  margin: 0 auto;
  padding: 30px;
  min-height: 42.1vh;

  @media (max-width:350px) {
    padding: 15px;
  }

  @media (max-width:1100px) {
    height: auto;
    max-width: 85%;
    margin-top: 20px;
  }

  @media (max-width:1280px) {
    max-width: 85%;
  }

  display: flex;
  flex-wrap: wrap;
`;

export const Paragraph = styled.p`
  flex: 1;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  max-width: 22ch;
  padding: 20px 50px;
  min-width: 20ch;
  margin: auto;

  @media (max-width:917px) {
    max-width: 30ch;
    font-size: 1.8rem;
    min-width: 15ch;
    padding: 0;
  }

  span {
    color: #FECD31;
  }
`;

export const PromotionClosed = styled.p`
  flex: 1;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  max-width: 22ch;
  padding: 20px 50px;
  min-width: 20ch;
  margin: auto;
  text-align: center;

  @media (max-width:917px) {
    max-width: 30ch;
    font-size: 1.8rem;
    min-width: 15ch;
    padding: 0;
  }

  span {
    color: #FECD31;
  }
`;

export const RulesLink = styled.a`
  background: none;
  border: none;
  text-decoration: underline;
  color: #FECD31;
  cursor: pointer;
  transition: .2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const InputsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 350px;
  padding: 20px;
  gap: 20px;

  @media (max-width:650px) {
    margin-top: 10px;
    padding: 0;
  }

  @media(max-width:500px) {
    min-width: 230px;
    padding: 0;
    margin-top: 30px;
  }

`;

export const InputsContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  div {
    display: flex;
    flex-direction: column;
  }

  label {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;

    &:last-child {
      margin-top: 10px;
    }
  }

  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    outline: none;
    font-size: 1.2rem;

    &::placeholder {
      color: white;
    }
  }
`;

export const ContainerCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  gap: 10px;

  label {
    display: flex;
    align-items: center;
    gap: 5px;
    word-break: break-word;
  }

  input[type='checkbox'] {
    display: none;
  }

  .checkmark {
    background-color: #ddd;
    border-radius: 5px;
    display: block;
    position: relative;
    min-height: 25px;
    min-width: 25px;
    cursor: pointer;
  }


  input[type='checkbox']:checked ~ .checkmark {
    background-color: green;
  }
  // customized checkmark
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkmark:after {
    left: 9px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #FECD31;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`;

export const CustomButton = styled.button`
  background-color: #FECD31;
  border: none;
  border-radius: 20px;
  color: rgb(0, 50, 0);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
  height: 50px;
  margin-bottom: 0px;
  text-align: center;

  &:disabled {
    opacity: .5;

    &:hover {
      opacity: .5;
    }
  }
  
  &:hover {
    background-color: #1e8449;
    opacity: 1;
    transform: translateY(0);
    transition-duration: .35s;
  }
  
  &:active {
    transform: translateY(2px);
    transition-duration: .35s;
  }
  
  &:hover {
    background-color: rgb(170, 150, 0);
  }
`