import React from "react";

import { CustomButton } from "./styles";

interface SubscribeButtonProps {
  isLoading: boolean
}

export function SubscribeButton({ isLoading = false }: SubscribeButtonProps) {
  return (
    <CustomButton
      type="submit"
      disabled={isLoading}
    >
      {!isLoading ? 'CONFIRMAR' : 'CARREGANDO...'}
    </CustomButton>
  );
}