import styled from 'styled-components';

export const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  width: 100%;
  min-height: 50vh;
  padding: 15px 50px 15px 0;
  flex-wrap: wrap;

  @media (max-width:859px) {
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }

  @media(max-width:780px) {
    
  }


  img {
    max-width: 100%;
    display: block;
  }
`;

export const Div1 = styled.div`
  flex: 1;
  max-width: 600px;
  min-width: 160px;
  display: flex;
  justify-content: space-between;

  @media(max-width:780px) {
    max-width: 100%;
  }

`;

export const Div2 = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  max-width: 600px;
  

  @media (max-width:859px) {
    grid-column: 1/-1;
    justify-content: center;
    max-width: 100%;  
  }

  @media(max-width:325px) {
    min-width: 160px;
  }

  @media(min-width:1800px) {
    justify-self: center;
  }
`;

export const LogoImg = styled.div`
`;

export const SeloImg = styled.div`
  padding: 20px;
  max-width: 80%;

  @media (max-width:600px) {
    max-width: 100%;
  }
`;

export const BannerImg = styled.div`
  max-width: 80%;

  @media (max-width:859px) {
    max-width: 50%;
  }

  @media (max-width:460px) {
    max-width: 70%;
  }


`;


export const Div3 = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 600px;
  min-width: 160px;

  @media (min-width:1150px) {
  
  }

  @media(max-width:780px) {
    justify-content: center;
    max-width: 100%;
  }

  @media(min-width:1800px) {
    justify-self: center;
  }

`;


