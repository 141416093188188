import React from 'react';

export function ErrorImage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="103" height="103" viewBox="0 0 103 103">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="51.5" cy="51.5" r="51.5" fill="#fe5131" />
      <g id="Group_1" data-name="Group 1" transform="translate(-1091.97 -386.955)">
        <rect id="Rectangle_12" data-name="Rectangle 12" width="72.461" height="13.984" rx="6.992" transform="matrix(-0.695, 0.719, -0.719, -0.695, 1173.167, 416.831)" fill="#fff" />
        <rect id="Rectangle_14" data-name="Rectangle 14" width="72.461" height="13.984" rx="6.992" transform="matrix(0.695, 0.719, -0.719, 0.695, 1122.832, 407.117)" fill="#fff" />
      </g>
    </svg>
  )
}