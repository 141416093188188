import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 20px 0 0 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderModal = styled.header`
  max-width: 80vw;
  margin: 0 auto;
  font-style: italic;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 20px 20px 0 20px;

  p {
    margin-top: 20px;
    color: white;
    letter-spacing: 0.3px;
  }

  span {
    color: #FECD31; 
    position: relative;
    display: block;
  }

  span::after {
    content: '';
    width: 200%;
    height: 2px;
    background-color: #fff;

    position: absolute;
    bottom: -30px;
    left: -190px;
  }
`;

export const Content = styled.section`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  font-style: italic;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 115px;
  padding: 0 20px 0 20px;

  @media (max-width:700px) {
    margin-bottom: 40px;
  }

  display: flex;
  flex-direction: column;

  margin-top: 60px;
  text-align: left;
  
  line-height: 18px;
  letter-spacing: 0.3px;

  div + div {
    margin-top: 20px;
  }

  div:last-child {
    margin-top: 20px;
  }

  p:first-child {
    margin-top: 20px;

  }

  p {
    color: #fff;
  }

  span {
    margin-top: 30px;
    color: #FECD31;
    font-weight: 500;
    font-style: italic;
  }

  ul {
    margin-top: 30px;

    li:before {
      content: '\u25CF';
      font-size: 12px;
      position: relative;
      top: -3px;
      left: -2px;
    }

    li {
      list-style: none;
      color: white;
    }
  }
`;

export const Footer = styled.footer`
  padding: 30px;
  background-color: #165423;
  width: 100%;

  p {
    margin-top: 30px;
    color: white;

    a {
      color: white;
    }
  }
`

export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;

  @media (max-width:700px) {
    gap: 20px;
  }
`

export const Img = styled.div`
  max-width: 150px;
  min-width: 70px;

  img {
    max-width: 100%;
    display: block;
  }
`

export const Button =  styled.button`
    position: absolute;
    top: 30px;
    right: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }

    img {
      max-width: 100%;
      display: block;
    }

    div {
      max-width: 35px;
    }
    
`

export const Aviso = styled.span`
    color: #FECD31; 
    position: relative;
    display: block;
    font-size: 1rem;
`