import React, { useState, useEffect } from "react";

import InputMask from "react-input-mask";

interface IInputProps {
  isReadOnly?: boolean;
  name: string;
  value: string;
  handleInputChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const GenericInputText: React.FC<IInputProps> = ({ handleInputChange, value, name, isReadOnly = false }) => {
  const INPUT_MASK = name === "cpf" ? "999.999.999-99" : "***-9**9";
  const PLACEHOLDER_MASK = name === "cpf" ? "***.***.***-**" : '***-****';
  const [maskedInputValue, setMaskedInputValue] = useState('*********');
  const LABEL_NAME = name === "cpf" ? "cpf" : "placa"

  useEffect(() => {
    if(name === "cpf") {
      setMaskedInputValue(value);
    } else {
      setMaskedInputValue(value);
    }
  }, [value]);

  return (
    <div>
      <label htmlFor={ name } className="input-alias">
        {LABEL_NAME}
      </label>
      <InputMask
      placeholder={PLACEHOLDER_MASK}
      readOnly={ isReadOnly }
      id={ name }
      mask={INPUT_MASK}
      maskChar="*"
      name={ name }
      type="text"
      value={ maskedInputValue }
      onChange={ (event) => handleInputChange && handleInputChange(event)}
    />
    </div>
  )
}

export { GenericInputText };