import React from 'react';

export const CheckboxRequired = () => (
  <p>Para continuar, você precisa aceitar o regulamento e compartilhar seus dados com a Porto Seguro.</p>
)

export const InvalidDocumentOrPlate = () => (
  <p>CPF ou placa inválidos. Verifique os dados digitados, e tente novamente.</p>
)

export const ClosedPromotion = () => (
  <p>Promoção encerrada.</p>
)

// export const ExpiredPlan = () => (
//   <p>Prazo de adesão ao <span> Plano e Assistência Auto expirado.</span></p>
// )