import React from "react";
import selo from '../../assets/selo.png';
import mecanica from '../../assets/mecanica.png';
import img2 from '../../assets/img2.png';
import { BannerImg, Container, Div1, Div2, Div3, LogoImg, SeloImg } from "./styles";

export function Hero() {
  return (
    <Container>
      <Div1>
        <LogoImg>
          <img src={img2} alt="Lubrax" />
        </LogoImg>
      </Div1>

      <Div3>
        <SeloImg>
          <img src={selo} alt="Cliente premmia trocou ganhou" />
        </SeloImg>
      </Div3>

      <Div2>
        <BannerImg>
          <img src={mecanica} alt="" />
        </BannerImg>
      </Div2> 
    </Container>
  )
}