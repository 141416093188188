import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  padding: 20px 0;

  background-color: rgb(0, 90, 0);
  color: white;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 12px;
    text-align: center;
    width: 90%;

    a {
      color: white;
      text-decoration: underline;
    }

    @media (min-width: 1280px) {
      font-size: 18px;
    }

    & + p {
      margin-top: 10px;
    }
  }
`;