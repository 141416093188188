import React from 'react';
import { Footer } from '../../components/Footer';
import { Hero } from "../../components/Hero";
import { Login2 } from '../../components/Login2/Login2';
import { Container } from './styles';

export function Home() {
  return (
    <Container>
      <Hero />
      <Login2 />
      <Footer />
    </Container>
  );
}
