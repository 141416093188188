import React from 'react';

import { Container } from './styles';

import lojas from '../../assets/lojas_2023.pdf'
import faq from '../../assets/faq.pdf'

export function Footer() {
  return(
    <Container>
      <p>
        Leia o regulamento completo em:<br /> <a href="https://www.postospetrobras.com.br/ganhe-mais-com/promocoes" target="_blank" rel="noreferrer">www.postospetrobras.com.br/ganhe-mais-com/promocoes.</a> Período de participação de 23/05/22 a 31/12/24.
      </p>
      <p>
        Para consultar a lista de lojas participantes, <a href={lojas} target="_blank" rel="noreferrer">clique aqui</a>.
        Caso queira ver as dúvidas frequentes, <a href={faq} target="_blank" rel="noreferrer">clique aqui</a>.
      </p>
    </Container>
  )
}