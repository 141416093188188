export default class Validations {
  // --------------------------------------------------
  // https://www.devmedia.com.br/validar-cpf-com-javascript/23916
  // --------------------------------------------------
  static document(strCPF: string): boolean {
    let Soma;
    let Resto;
    Soma = 0;
    const serializedString = strCPF.replace(/[^\d]+/g,'');
    if (serializedString == "00000000000") return false;

    for (let i=1; i<=9; i++) Soma = Soma + parseInt(serializedString.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11))  Resto = 0;
      if (Resto != parseInt(serializedString.substring(9, 10)) ) return false;

    Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(serializedString.substring(i-1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11))  Resto = 0;
      if (Resto != parseInt(serializedString.substring(10, 11) ) ) return false;
      return true;
  }

  // --------------------------------------------------
  // https://blog.dbins.com.br/validando-placas-de-veiculos-com-javascript
  // --------------------------------------------------
  static plate(plate: string): boolean {
    let resposta = false;
    const placa = plate.replace(/[^a-zA-Z0-9]/g,'');

    const regexPlaca = /^[a-zA-Z]{3}[0-9]{4}$/;
    const regexPlacaMercosulCarro = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/;
    const regexPlacaMercosulMoto = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}[0-9]{1}$/;

    if(regexPlaca.test(placa)){
      resposta = true;
    }

    if(regexPlacaMercosulCarro.test(placa)){
      resposta = true;
    }

    if(regexPlacaMercosulMoto.test(placa)){
      resposta = true;
    }
    return resposta;
   }
}