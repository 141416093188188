import { IUserDTO } from "../@types/userDTO";

const URL = String(process.env.REACT_APP_API_URL)

export async function consultViability({ plate, document }:IUserDTO) {
  const response = await window.fetch(URL, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      plate,
      document,
    }),
  });

  return response;
}