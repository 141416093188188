import styled from "styled-components";

export const Container =  styled.div`
  max-width: 500px;
  position: relative;
  border-radius: 25px;
  background-color: #2E7338;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  overflow: visible;

  font-weight: 500;
  font-size: 1.5rem;

  padding: 20px 60px 20px 60px;

  @media (max-width:500px) {
    max-width: 300px;
    padding: 10px;
  }


  svg {
    padding: 20px 0 0 0;
    max-width: 90px;
  }

  p {
    color: white;
    letter-spacing: 0.5px;
    padding: 20px 20px;
    max-width: 400px;
    width: 100%;
    font-style: italic;
    font-weight: bold;

    @media (max-width:500px) {
    font-size:18px;  
    padding: 10px;
  }
  }
`;

export const Button =  styled.button`
    position: absolute;
    top: -5px;
    right: -4px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }

    img {
      max-width: 100%;
      display: block;
    }

    div {
      max-width: 35px;
    }
    
`