import styled from "styled-components";
import bgImage from "../../assets/23462_FUNDO_LANDINGPAGE.jpg";

export const Container = styled.main`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  overflow-y: auto;
  overflow-x: hidden;
`
