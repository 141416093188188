import React, { useState, useEffect } from 'react';
import validations from '../../utils/Validations';
import { consultViability } from '../../services/api';
import { GenericInputText } from '../GenericInputText';
import { SubscribeButton } from '../SubscribeButton';
import { ErrorModal } from '../ErrorModal';
import { SuccessModal } from '../SuccessModal';

import { Container, Paragraph, RulesLink, InputsContainer, ContainerCheckBox, InputsContainerContent, PromotionClosed, CustomButton } from './styles';
import { NewRegulamentoModal } from '../NewRegulamentoModal/NewRegulamentoModal';

const URL = String(process.env.REACT_APP_API_URL_2)

export function Login2() {
  const [document, setDocument] = useState<string>('');
  const [plate, setPlate] = useState<string>('');

  const [isValidFields, setIsValidFields] = useState<boolean>(false);

  const [apiResponse, setApiResponse] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [showRegulamentoModal, setShowRegulamentomodal] = useState<boolean>(false);

  const [inputRegulamentoChecked, setInputRegulamentoChecked] = useState<boolean>(false);
  const [inputShareInfoChecked, setInputShareInfoChecked] = useState<boolean>(false);

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errType, setErrorType] = useState<string>('');

  const [hasSuccess, setHasSuccess] = useState<boolean>(false);
  const [iconSuccess, setIconSuccess] = useState<boolean>(false);

  const [promotionIsOpen, setPromotionIsOpen] = useState(true)
  const [promotionIsLoading, ] = useState(false)
  const [promotionHasError, ] = useState(false)

  const fetchData = async () => {
    setPromotionIsOpen(true)
    // setPromotionIsLoading(true)
    // try {
    //   const response = await fetch('https://devapi.promocaolubraxmais.com.br/date');

    //   const data = await response.json()

    //   const todayUTC = new Date(data.date)
    //   const expirationDate = new Date('2024-12-31T23:59:59')

    //   if (todayUTC.toISOString() >= expirationDate.toISOString()) {
    //     setPromotionIsOpen(false)
    //   } else {
    //     setPromotionIsOpen(true)
    //   }
    //   setPromotionHasError(false)
    // } catch (error) {
    //   setPromotionHasError(true)
    // } finally {
    //   setPromotionIsLoading(false)
    // }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const checkFieldsValidity = validations.document(document) && validations.plate(plate);
    if (checkFieldsValidity) {
      setIsValidFields(true)
    } else {
      setIsValidFields(false)
    }

  }, [document, plate])

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name: fieldName, value } = event.target;
    if (fieldName === 'cpf') {
      setDocument(value)
    } else {
      setPlate(value);
    }
  }

  const handleInputSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await fetch(`${URL}/date`);

      const data = await response.json()

      const todayUTC = new Date(data.date)
      const expirationDate = new Date('2024-12-31T23:59:59')

      if (todayUTC.toISOString() >= expirationDate.toISOString()) {
        setErrorType('closedPromotion')
        setPromotionIsOpen(false)
        return setShowErrorModal(true)
      } else {
        setPromotionIsOpen(true)
      }
    } catch (error) {
      console.log(error)
    }


    if (!inputRegulamentoChecked || !inputShareInfoChecked) {
      setErrorType('CheckboxRequired')
      return setShowErrorModal(true);
    }

    if (isValidFields) {
      setIsLoading(true)
      const response = await consultViability({ document, plate });
      const statusCode = response.status;
      const { status, message } = await response.json();
      if (statusCode === 200) {
        setIconSuccess(true);
      } else {
        setIconSuccess(false);
      }
      if (status === 'error') {
        setErrorType('Ineligible');
        setApiResponse(message);
        setShowErrorModal(true);
      } else {
        setHasSuccess(true);
      }
      setIsLoading(false)
    } else {
      setErrorType('InvalidDocumentOrPlate')
      setShowErrorModal(true);
      setIconSuccess(false);
    }
  }

  const [isOpenModalRegulamento, SetIsOpenModalRegulamento] = useState(false)

  function fecharModalRegulmento() {
    SetIsOpenModalRegulamento(false)
  }

  function abrirModalRegulamento() {
    SetIsOpenModalRegulamento(true);
  }

  function handleCheckInputTrue() {
    setInputRegulamentoChecked(true);
  }

  function handleCheckInputFalse() {
    setInputRegulamentoChecked(false);
  }

  const handleCheckInput = (value: boolean) => {
    setInputRegulamentoChecked(value);
  }

  const handleCheckInputWithKeySpaceOrEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setInputShareInfoChecked(!inputShareInfoChecked);
    }
  }

  return (
    <Container onSubmit={(event: React.FormEvent<HTMLFormElement>) => handleInputSubmit(event)}>
      <NewRegulamentoModal isOpen={isOpenModalRegulamento} fecharModal={fecharModalRegulmento} checkInput={handleCheckInputTrue} checkInputFalse={handleCheckInputFalse} />

      {promotionIsOpen && !promotionHasError && (
        <>
          <Paragraph>
            COMPLETE SEUS DADOS AQUI <span>E FINALIZE SUA ADESÃO PARA GARANTIR SEU PRÊMIO.</span>
          </Paragraph>
    
            <InputsContainer>
    
            <InputsContainerContent>
              <GenericInputText name="cpf" handleInputChange={handleInputChange} value={document} />
    
              <GenericInputText name="plate" handleInputChange={handleInputChange} value={plate} />
            </InputsContainerContent>
    
            <ContainerCheckBox>
              <label htmlFor="accept_terms" className="checkbox-alias">
                <input type="checkbox" name="accept_terms" id="accept_terms" checked={inputRegulamentoChecked} />
    
                <span className="checkmark" role="button" tabIndex={0}
                  onClick={() => handleCheckInput(!inputRegulamentoChecked)}
                  onKeyDown={() => handleCheckInput(!inputRegulamentoChecked)}>
                </span>
    
                <span>
                  Aceito e concordo com o regulamento da promoção. <RulesLink onClick={abrirModalRegulamento}>Clique aqui</RulesLink> e confira o regulamento.
                </span>
              </label>
    
              <label htmlFor="accept_share_infos" className="alias">
                <input
                  type="checkbox"
                  name="accept_terms"
                  id="accept_terms"
                  checked={inputShareInfoChecked}
                />
                <span
                  role="button"
                  tabIndex={0}
                  className="checkmark share"
                  onClick={() => setInputShareInfoChecked(!inputShareInfoChecked)}
                  onKeyDown={handleCheckInputWithKeySpaceOrEnter}
                />
                <span>
                  Aceito compartilhar meus dados com a Porto Seguro (nome/email/telefone/CPF/placa do carro).
                </span>
              </label>
            </ContainerCheckBox>
    
            <SubscribeButton isLoading={isLoading} />
            </InputsContainer>
        </>
      )}

      {!promotionIsOpen && !promotionHasError && (
        <PromotionClosed>
          {promotionIsLoading ? 'CARREGANDO...' : 'PROMOÇÃO ENCERRADA'}
        </PromotionClosed>
      )}

      {promotionHasError && (
        <PromotionClosed>
          <p style={{marginBottom: '10px'}}>ERRO AO CARREGAR FORMULÁRIO</p>
          <CustomButton
            onClick={fetchData}
            type='button'>
            TENTAR NOVAMENTE
          </CustomButton>
        </PromotionClosed>
      )}

      {
        showErrorModal &&
        <ErrorModal
          err={errType}
          isOpen={showErrorModal}
          closeModal={setShowErrorModal}
          apiResponse={apiResponse}
          iconSuccess={iconSuccess}
        />
      }
      {
        hasSuccess &&
        <SuccessModal
          isOpen={hasSuccess}
          closeModal={() => setHasSuccess(false)}
        />
      }
    </Container>
  );
}
