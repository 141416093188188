import React from 'react';
import { Home } from './pages/Home';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from './styles/global';

function App() {
  return (
    <>
      <BrowserRouter>
        <GlobalStyle />
        <Home />
      </BrowserRouter>
    </>
  );
}

export default App;
