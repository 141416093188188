import React, { useState, useEffect, SetStateAction } from "react";

import ReactModal from "react-modal";

import { Button, Container } from './styles';
import { CheckboxRequired, ClosedPromotion, InvalidDocumentOrPlate } from '../../utils/errorMessages';

import { ErrorImage } from "../../assets/ErrorImage";
import { SuccessImage } from "../../assets/SuccessImage";
import buttonImg from '../../assets/button.png'

const modalStyle = {
  overlay: {
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0, 0, 0, 0.6)",

    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    position: "fixed" as "fixed",
    inset: '0',
  },

  content: {
    backgroundColor: "rgba(0, 70, 0, 1)",
    border: 'none',
    color: "red",
    margin: "0 auto",
    padding: "0",
    width: "100vw",
    boxSize: "border-box",
    height: "100vh",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    position: "relative" as "relative",
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    overflow: "visible" as "visible",
    top: "0vh",
    left: "0vw",
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    textAlign: "center" as "center",
  }
}


type ErrorModalProps = {
  err: string;
  isOpen: boolean;
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  apiResponse?: string;
  iconSuccess: boolean;
}

export function ErrorModal({ err = '', isOpen, closeModal, apiResponse, iconSuccess }: ErrorModalProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(isOpen);
  const [errMessage, setErrMessage] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [modalOpen]);

  useEffect(() => {
    if (err !== '') {
      setErrMessage(err);
    }
  }, [err]);

  function parseMessage(message: string | undefined) {
    if (message) {
      const parsedMessage = message.replaceAll('\r\n', '<br /><br />').replaceAll('\\r\\n', '<br /><br />')

      return parsedMessage
    }

    return ''
  }

  const handleCloseModal = () => closeModal(false);

  return (
    <ReactModal
      className="error"
      isOpen={modalOpen}
      style={modalStyle}
      ariaHideApp={false}
    >
      <Container>
        {iconSuccess ? <SuccessImage /> : <ErrorImage />}
        {
          errMessage === 'CheckboxRequired'
            ? CheckboxRequired()
            : errMessage === 'InvalidDocumentOrPlate'
              ? InvalidDocumentOrPlate()
              : errMessage === 'closedPromotion'
              ? ClosedPromotion()
              : <p dangerouslySetInnerHTML={{ __html: parseMessage(apiResponse) }}></p>
        }
        <Button
          type="button"
          onClick={handleCloseModal}
        >
          <div>
            <img src={buttonImg} alt="Botão de fechar o modal" />
          </div>

        </Button>
      </Container>
    </ReactModal>
  );
}