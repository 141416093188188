import styled from "styled-components";

export const CustomButton = styled.button`
  background-color: #FECD31;
  border: none;
  border-radius: 20px;
  color: rgb(0, 50, 0);
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
  height: 50px;
  margin-bottom: 0px;
  text-align: center;

  &:disabled {
    opacity: .5;

    &:hover {
      opacity: .5;
    }
  }
  
  &:hover {
    background-color: #1e8449;
    opacity: 1;
    transform: translateY(0);
    transition-duration: .35s;
  }
  
  &:active {
    transform: translateY(2px);
    transition-duration: .35s;
  }
  
  &:hover {
    background-color: rgb(170, 150, 0);
  }
`