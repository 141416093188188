import React from 'react';

export function SuccessImage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 103 103">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="51.5" cy="51.5" r="51.5" fill="#fecd31"/>
      <g id="Group_1" data-name="Group 1" transform="translate(-1084.178 -385.536)">
        <rect id="Rectangle_11" data-name="Rectangle 11" width="43.222" height="12.712" rx="6.356" transform="matrix(0.695, 0.719, -0.719, 0.695, 1102.333, 429.032)" fill="#fff"/>
        <rect id="Rectangle_12" data-name="Rectangle 12" width="72.461" height="13.984" rx="6.992" transform="matrix(-0.695, 0.719, -0.719, -0.695, 1173.167, 416.831)" fill="#fff"/>
      </g>
    </svg>
  )
}